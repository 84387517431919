import React, {useCallback, useMemo, useRef, useEffect, Fragment, useState} from "react";
import * as R from "ramda";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { colors, Icon } from "@wfp/ui";
import useSize from "@react-hook/size";
import { useFormContext, useWatch } from "react-hook-form";
import { useResponsiveHook, useBoolean } from "hooks";

import { Text } from "components";
import { RowStyled, ColStyled } from "components/utils";
import menuMessages from "containers/Menu/messages";
import { selectDaysInWeek, selectWeekCount } from "containers/Menu/selectors";
import { selectRateFromUSD } from "containers/App/selectors";
import { saveInResultsPdf } from "containers/PDF/actions";
import { FIELDS, NEW_ITEM_INITIAL_VALUES } from "containers/Results/constants";
import messages from "containers/Results/messages";
import { selectInformations } from "containers/Results/selectors";
import { reorderList, getPrices, getCosts } from "containers/Results/utils";
import { USD } from "utils/constants";
import AddNewItem from "./AddNewItem";
import DayBox from "./DayBox";
import Footer from "./Footer";
import { MenuCompositionContainer, TitleContainer, FoodGroups, FoodGroupsInnerDiv, DroppableInnerDiv } from "./styles";
import { iconInfoSolid } from "@wfp/icons";
import styled from "styled-components";

const MenuComposition = ({menuComposition, setDayToShow, currency, menuScores, enabledMenuScoreFeature}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {control, reset, getValues, setValue} = useFormContext();
  const newFoodItem = useWatch({control, name: `${FIELDS.DRAFT}.${FIELDS.NEW_ITEM}`});
  const {isTabletOrMobile} = useResponsiveHook();

  const foodItems = useSelector(selectInformations);
  const daysInWeek = useSelector(selectDaysInWeek);
  const weekCount = useSelector(selectWeekCount);
  const conversionRate = useSelector(selectRateFromUSD);

  const $menu = useRef();
  const [menuWidth] = useSize($menu);

  const useCurrency = useMemo(() => (R.equals(USD, currency) ? 1 : conversionRate), [conversionRate, currency]);

  const days = useMemo(() => new Array(weekCount * daysInWeek).fill().map((_, i) => i + 1), [daysInWeek, weekCount]);

  useEffect(() => {
    if (!R.isEmpty(menuComposition)) {
      const recipes = getValues()[FIELDS.INCLUDED_RECIPES];
      dispatch(saveInResultsPdf({values: menuComposition, recipes, daysInWeek, days}));
    }
  }, [days, daysInWeek, dispatch, getValues, menuComposition]);

  const onDragEnd = useCallback(
    ({source, destination}) => {
      // Releasing food item NOT on a day? Do nothing
      if (!destination) return;

      // Releasing food item on the same day? Do nothing
      if (source.droppableId === destination.droppableId) return;

      // Handle food item moved from a day to a different one
      const sourceList = [...menuComposition[source.droppableId]];
      const destinationList = [...menuComposition[destination.droppableId]];

      // Change "day" property for the moved item (eg. Moving from day_2 to day_4 => update it to 4) or nothing will happen when saving all changes
      const [removed] = sourceList.splice(source.index, 1);
      destinationList.splice(destination.index, 0, {...removed, day: Number(destination.droppableId.split("_")[1])});

      const result = {};
      result[source.droppableId] = sourceList;
      result[destination.droppableId] = destinationList;

      // Reorder data
      const sourceOrderedItems = reorderList(result[source.droppableId]);
      const destinationOrderedItems = reorderList(result[destination.droppableId]);

      const previousMenuComposition = getValues()[FIELDS.MENU_COMPOSITION];

      setValue(FIELDS.MENU_COMPOSITION, {
        ...previousMenuComposition,
        [source.droppableId]: sourceOrderedItems,
        [destination.droppableId]: destinationOrderedItems,
      });
    },
    [getValues, menuComposition, setValue]
  );

  const getFoodGroupsCount = useCallback(
    (day) => {
      if (R.isNil(menuComposition) || R.isEmpty(menuComposition)) return 0;
      const currentDay = menuComposition[`day_${day}`];

      let foodItemUniqueGroups = []
      if (!R.isNil(currentDay)) {
        foodItemUniqueGroups = currentDay.map((foodItem) => foodItem.major_group)
      }

      let recipeItemUniqueGroups = []
      const day_recipes = getValues()[FIELDS.INCLUDED_RECIPES][`day_${day}`];
      const getRecipeFoodGroups = R.compose(R.map(R.prop('major_group')), R.flatten, R.map(R.values), R.map(R.prop('ingredients')))
      if (day_recipes !== undefined && !R.isEmpty(day_recipes[`day_${day}`])) {
        recipeItemUniqueGroups = getRecipeFoodGroups(day_recipes)
      }

      const uniqueFoodGroups = [...new Set(foodItemUniqueGroups.concat(recipeItemUniqueGroups))]
      return uniqueFoodGroups.length;
    },
    [menuComposition, getValues]
  );

  const getMealAvgCost = useCallback(
    (day) => {
      if (R.isNil(menuComposition) || R.isEmpty(menuComposition))
        return `${currency.value === USD.value ? "$" : currency.value} 0`;

      let recipeDayItems = []
      const day_recipes = getValues()[FIELDS.INCLUDED_RECIPES][`day_${day}`];
      const getRecipeFoodItems = R.compose(R.flatten, R.map(R.values), R.map(R.prop('ingredients')))

      if (day_recipes !== undefined && !R.isEmpty(day_recipes[`day_${day}`])) {
        recipeDayItems = getRecipeFoodItems(day_recipes)
      }
      const currentDayItems = menuComposition[`day_${day}`].concat(recipeDayItems);
      if (R.isNil(currentDayItems)) return `${currency.value === USD.value ? "$" : currency.value} 0`;

      return `${currency.value === USD.value ? "$" : currency.value} ${(
        getCosts(currentDayItems).cost * useCurrency
      ).toFixed(4)}`;
    },
    [menuComposition, useCurrency, currency.value, getValues]
  );

  const getTotalWeight = useCallback(
    (day: number) => {
      if (R.isNil(menuComposition) || R.isEmpty(menuComposition)) return 0;
      const currentDay: { quantity: number }[] = menuComposition[`day_${day}`];
      const totalWeight = currentDay.reduce((a: number, b: { quantity: number }) => a + Number(b.quantity), 0);
      return `${totalWeight.toFixed(1)} g`;
    },
    [menuComposition]
  );

  // const getMealScore = useCallback((day) => (R.has(day, menuScores) ? menuScores[day]?.total_score.toFixed(4) : "0"), [menuScores]);
  const getMealScore = useCallback((day: number) => (R.has(day, menuScores) ? menuScores[day] : null), [menuScores]);

  const [showModal, {toggle: toggleShowModal, setFalse: hideModal}] = useBoolean();

  const onModalClose = useCallback(() => {
    const values = getValues();
    reset({...values, [FIELDS.DRAFT]: {...values[FIELDS.DRAFT], [FIELDS.NEW_ITEM]: NEW_ITEM_INITIAL_VALUES}});
    hideModal();
  }, [getValues, hideModal, reset]);

  const addItem = useCallback(() => {
    const foodItem = foodItems.find((foodItem) => foodItem.food_item === newFoodItem[FIELDS.ITEM]);
    const weekNumber = Number(newFoodItem[FIELDS.WEEK].replace("week_", ""));

    // weekNumber: 1 => startingDay: (weekNumber * daysInWeek) - daysInWeek + 1 => 3 - 3 + 1 = we start from (day_)1
    // weekNumber: 2 => startingDay: (weekNumber * daysInWeek) - daysInWeek + 1 => 6 - 3 + 1 = we start from (day)_4
    const startingDay = weekNumber * daysInWeek - daysInWeek + 1;
    const endingDay = startingDay + (daysInWeek - 1);

    Object.keys(menuComposition).forEach((day) => {
      const currentDayContent = menuComposition[day];
      const dayNumber = Number(day.replace("day_", ""));

      if (newFoodItem[FIELDS.WEEK] === "all" || (dayNumber >= startingDay && dayNumber <= endingDay)) {
        const additionalFoodItem = {
          ...foodItem,
          day: dayNumber,
          quantity: newFoodItem[FIELDS.QUANTITY],
          ...getPrices({...foodItem, quantity: newFoodItem[FIELDS.QUANTITY]}),
        };
        setValue(`${FIELDS.MENU_COMPOSITION}.${day}`, reorderList([...currentDayContent, additionalFoodItem]));
      }
    });

    onModalClose();
  }, [daysInWeek, foodItems, menuComposition, newFoodItem, onModalClose, setValue]);


  const IconWrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
  
    svg {
      fill: ${colors["brand-01"].hex};
      width: 16px;
      height: 16px;
    }
`;
  const [currentScoreDay, setCurrentScoreDay] = useState<string | null>(null);
  const extraRow = useCallback(
    (days, label, dataTestId, valueFunction) => (
      <RowStyled>
        {days.map((day, index) => {
          return (
            <FoodGroups key={day} maxWidth={menuWidth / daysInWeek} hasBorderRight={index !== days.length - 1}>
              { dataTestId === 'food-meal-score' ? (
                <div>
                  <FoodGroupsInnerDiv>
                    <ColStyled className="start-xs" padding="0">
                      <Text value={label}/>
                    </ColStyled>
                    <ColStyled className="end-xs">

                      <IconWrapper data-icon-wrapper-id={`${dataTestId}-container`} onClick={() => (currentScoreDay===day) ? setCurrentScoreDay(null) :setCurrentScoreDay(day)}>
                        <Text data-test-id={dataTestId} defaultColor={colors["brand-01"].hex} value={valueFunction(day)?.total_score.toFixed(2) || "0"}/>&nbsp;
                        <Icon icon={iconInfoSolid} description="info" fill={colors["brand-01"].hex} />
                      </IconWrapper>
                    </ColStyled>
                  </FoodGroupsInnerDiv>
                  {currentScoreDay===day && Object.entries(valueFunction(day)?.metrics || []).map(([metric_label, metric_val]) => (

                        <FoodGroupsInnerDiv>
                          <ColStyled className="start-xs" padding="0">
                            <Text value={metric_label} />
                          </ColStyled>
                          <ColStyled className="end-xs">
                            <Text value={metric_val.metric_score.scaled_score.toFixed(2)}/>
                          </ColStyled>
                        </FoodGroupsInnerDiv>
                  ))}
                </div>
                ) :
                (
                  <FoodGroupsInnerDiv>
                    <ColStyled className="start-xs" padding="0">
                      <Text value={label}/>
                    </ColStyled>
                    <ColStyled className="end-xs">
                      <Text data-test-id={dataTestId} defaultColor={colors["brand-01"].hex} value={valueFunction(day)}/>
                    </ColStyled>
                  </FoodGroupsInnerDiv>
                )
              }

            </FoodGroups>
          );
        })}
      </RowStyled>
    ),
    [daysInWeek, menuWidth, currentScoreDay, setCurrentScoreDay,IconWrapper]
  );


  return (
    <div ref={$menu} style={{width: "100%"}}>
      <MenuCompositionContainer>
        <DragDropContext onDragEnd={onDragEnd}>
          {R.splitEvery(daysInWeek, days).map((days, index) => {
            return (
              <Fragment key={`week-${index}`}>
                <TitleContainer>
                  <Text
                    fontSize="20px"
                    bold
                    transform="uppercase"
                    defaultColor={colors["text-02"].hex}
                    value={intl.formatMessage(isTabletOrMobile ? menuMessages.shortWeek : menuMessages.week, {
                      week: index + 1,
                    })}
                  />
                  {index === 0 && (
                    <AddNewItem
                      buttonText={intl.formatMessage(messages.addNewItemInAll)}
                      showModal={showModal}
                      toggleModal={toggleShowModal}
                      onClose={onModalClose}
                      onSubmit={addItem}
                    />
                  )}
                </TitleContainer>

                <RowStyled>
                  {days.map((day) => {
                    return (
                      <Droppable key={`day_${day}`} droppableId={`day_${day}`}>
                        {(provided, snapshot) => (
                          <DroppableInnerDiv ref={provided.innerRef} maxWidth={menuWidth / daysInWeek}>
                            <DayBox
                              key={`day-${day}-${index}`}
                              day={day}
                              setDayToShow={setDayToShow}
                              listKey={`day_${day}`}
                            />
                            {provided.placeholder}
                          </DroppableInnerDiv>
                        )}
                      </Droppable>
                    );
                  })}
                </RowStyled>
                {extraRow(days, intl.formatMessage(messages.totalMealWeight), "food-meal-total-weight", getTotalWeight)}
                {extraRow(days, intl.formatMessage(messages.foodGroups), "food-groups-count", getFoodGroupsCount)}
                {extraRow(days, intl.formatMessage(messages.averageCost), "food-meal-avg-cost", getMealAvgCost)}
                {enabledMenuScoreFeature &&
                  extraRow(days, intl.formatMessage(messages.mealScore), "food-meal-score", getMealScore)}
              </Fragment>
            );
          })}
        </DragDropContext>
        <Footer/>
      </MenuCompositionContainer>
    </div>
  );
};

export default MenuComposition;
